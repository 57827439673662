import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["selectAllButton", "productCheckbox", "errorContainer", "submitButton"]

  async submitForm(event) {
    event.preventDefault();  // Prevent default form submission

    const form = event.target;

    // Validate checkboxes (ensure at least one is checked)
    if (!this.isAtLeastOneChecked()) {
      this.showErrorMessage();
      return;
    }

    // If validation passed, hide the error message
    this.hideErrorMessage();

    try {
      // Use the site key injected from the Rails backend
      const recaptchaToken = await grecaptcha.execute(window.recaptchaSiteKey, { action: 'submit_demo_form' });

      // Add the reCAPTCHA token to the form
      form.querySelector('#recaptchaToken').value = recaptchaToken;

      const formData = new FormData(form);

      // Capture pageUri and pageName and append them to the form data
      const pageUri = window.location.href;
      const pageName = document.title;
      formData.append('pageUri', pageUri);
      formData.append('pageName', pageName);

      const response = await fetch(form.action, {
        method: 'POST',
        headers: { 'X-CSRF-Token': formData.get('authenticity_token') },
        body: formData
      });

      if (!response.ok) throw new Error('Form submission failed.');

      const result = await response.json();
      if (result.status === 'ok') {
        this.loadRevenueHeroWidget(result.hero_data.router_id, result.hero_data.fields);
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  }


  toggleSelectAll() {
    this.allSelected = !this.allSelected;
    this.productCheckboxTargets.forEach(checkbox => {
      checkbox.checked = this.allSelected;
    });

    // Update button text
    this.selectAllButtonTarget.textContent = this.allSelected ? 'Deselect All' : 'Select All';
  }

  isAtLeastOneChecked() {
    // Check if at least one checkbox is selected
    return this.productCheckboxTargets.some(checkbox => checkbox.checked);
  }

  showErrorMessage() {
    // Show the error message if no checkboxes are selected
    this.errorContainerTarget.classList.remove('hidden');
  }

  hideErrorMessage() {
    // Hide the error message if validation passes
    this.errorContainerTarget.classList.add('hidden');
  }

  loadRevenueHeroWidget(routerId, fields) {
    const hero = new RevenueHero({ routerId: routerId, showLoader: true });
    hero.submit(fields).then(rhData => {
      hero.dialog.open(rhData);
    }).catch(error => console.error('Error with RevenueHero:', error));
  }
}